body {
  @apply font-primary text-black text-base !p-0 antialiased;
}

@layer base {

  h1, h2, h3, h4, h5, h6,
  .h1, .h2, .h3, .h4, .h5,.h6 {
    @apply font-light mb-6 md:mb-11;
  }

  h1,
  .h1 {
    @apply text-h1-m md:text-h1 font-tiempos font-light;
  }

  h2,
  .h2 {
    @apply text-h2-m md:text-h2 font-tiempos font-light;
  }

  h3,
  .h3 {
    @apply text-h3-m md:text-h3 font-tiempos font-light;
  }

  h4,
  .h4 {
    @apply text-h4-m md:text-h4 font-gothamBold;
  }

  h5,
  .h5 {
    @apply text-h5-m md:text-h5 font-gothamBold;
  }

  h6,
  .h6 {
    @apply text-h6-m md:text-h6 font-gothamBold;
  }

  p {
    @apply mb-6;
  }

  ol,
  ul {
    @apply my-6;
    @apply pl-8;
  }
  ol {
    @apply list-decimal;
  }

  ul {
    @apply list-disc;
  }
  li > p{
    @apply mb-0;
  }
  ol ol,
  ol ul,
  ul ol,
  ul ul{
    @apply my-4;
  }

  blockquote {
    @apply mx-0 lg:mx-16 my-8 lg:my-11 px-6 pr-0 lg:px-11 py-6 lg:py-11 border-t border-b border-black h5 relative z-0;
    p{
      @apply h5 mb-6;
      &:last-child{
        @apply h6 mb-0;
      }
    }
    &:before{
      content: '\201C';
      font-size: 26px;
      height: 15px;
      line-height: 1;
      @apply font-tiempos block absolute -ml-4 -translate-x-full;
      @screen lg {
        font-size: 64px;
      }
    }
  }
  hr {}
  .sub-large {
    @apply text-[1.125rem] leading-[1.42rem] font-gothamBold;
  }

  .text-large {
    @apply text-[18px] leading-[26px];
  }

  .sub-regular {
    @apply text-[1rem] leading-[1.25rem] font-gothamBold;
  }

  .text-regular {
    @apply text-[16px] leading-[24px];
  }

  .sub-small {
    @apply text-[0.875rem] leading-[1.1rem] font-gothamBold;
  }

  .text-small {
    @apply text-[14px] leading-[22px];
  }

  .sub-xsmall {
    @apply text-[0.75rem] leading-[0.94rem] font-gothamBold;
  }

  .text-xsmall {
    @apply text-[0.75rem] leading-[1.125rem];
  }

  .sub-xxsmall {
    @apply text-[0.6875rem] leading-[0.79rem] font-gothamBold;
  }

  .text-xxsmall {
    @apply text-[0.6875rem] leading-[0.79rem];
  }

  .text-header {
    @apply text-h2-m lg:text-h3 font-tiempos;
  }
  /*
    LINKS
  */
  a {
    @apply font-primary hover:no-underline underline transition-colors duration-300;
  }

  .focus-link-no-link {
    @apply font-primary hover:no-underline underline transition-colors duration-300 font-gothamBold underline-offset-[6px] decoration-2;
  }

  .focus-link {
    @apply text-black transition-all font-gothamBold underline-offset-4 decoration-2 no-underline inline-block leading-normal border-b-2 border-black hover:border-transparent hover:text-black;
  }

  .focus-link-white {
    @apply text-white transition-all font-gothamBold underline-offset-4 decoration-2 no-underline inline-block leading-normal border-b-2 border-white hover:border-transparent hover:text-white;
  }

  b,strong{
    @apply font-gothamBold font-normal;
  }
}
