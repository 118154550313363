#main-navbar{
	> ul{
		&:hover{
			> li > a{
				@apply text-grey-3;
			}
		}
		> li > a{
			@apply border-b-2 border-transparent hover:border-black hover:text-black;
		}
		> li{
			&:hover{
				> a{
					@apply border-b-2 border-black text-black;
					> span.arrow{
						@apply -rotate-180;
					}
				}
			}
		}
	}
	li.has-child > a{
		@apply transition-all border-b-2 border-transparent pr-4;
		> span.arrow{
	    height: 7px;
	    width: 10px;
			@apply absolute right-0 top-1/2 -translate-y-1/2 transition-transform;
		}
		&:hover > span.arrow{
			@apply -rotate-180;
		}
	}
	li.level-1:hover{
		.mega-menu-container,
		.dropdown-menu-container{
			@apply pointer-events-none opacity-100 scale-y-100;
			&:before{
				@apply pointer-events-auto;
			}
			.mega-menu,
			.dropdown-menu{
				@apply pointer-events-auto;
			}
		}
	}
}
.mega-menu-container,
.dropdown-menu-container{
	@apply pointer-events-none transition-all opacity-0 scale-y-0 origin-top delay-300;
	margin-top: 1px;
	&:before{
		content: "";
		height: 30px;
		@apply absolute block w-full top-0 left-0 -translate-y-full;
	}
	ul{
		@apply list-none m-0;
	}
	li{
		@apply list-none first:mt-0;
		> a{
			@apply py-0;
			&:hover{
				.promo-text{
					@apply underline;
				}
			}
		}
		&.level-2 .has-hover-cta + div.hover-cta{
			@apply absolute z-1 top-0 right-0 w-3/4 h-full bg-white hidden opacity-0 pointer-events-none scale-x-0 origin-left delay-300 transition-all;
		}
		&.level-2 .has-hover-cta:hover + div.hover-cta{
			@apply block pointer-events-auto opacity-100 scale-x-100;
			&:before{
				content: '';
				@apply absolute w-14 h-full left-0 -translate-x-full;
			}
		}
	}
}

.mega-menu-container .mega-menu {
  overflow-y: auto;
}
.bg-overlay{
	@apply fixed overflow-visible w-full;
  top: var(--topPosition) !important;

	&:before{
		content: '';
		@apply absolute top-0 left-0 w-full h-full opacity-50 bg-black z-[2001];
	}

  &.drawer-open:before {
    @apply !z-[2000];
  }
}
#menu-drawer{
	li.level-2.has-child{
		> button{
			&:before,
			&:after{
				content: '';
				@apply border-t-2 border-black w-4 h-0 absolute z-0 right-4 top-1/2 -translate-y-1/2;
			}
			&:after{
				@apply rotate-90 transition-all duration-300;
			}
		}
		> ul{
			@apply hidden;
		}
	}
	li.level-2.is-open{
		> button{
			&:after{
				@apply -rotate-180;
			}
		}
		> ul{
			@apply block;
		}

    > ul.featured-column {
      @apply flex flex-row flex-wrap;
    }
	}
}
.menu-drawer__footer{
	.country-selector{
		&:after{
			content: url('data:image/svg+xml; utf8, <svg width="8" height="5" viewBox="0 0 15 9" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 1L7.5 7.5L14 1" stroke="%23555759" stroke-width="2" stroke-linecap="round"/></svg>');
			@apply h-full border-0 w-2 -top-0.5;
		}
		select{
			@apply border-0 px-0 text-sm text-black pr-4;
		}
	}
}