@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

/* VERSION - 1.0 */
@import '/components/extend-layers.css';

@import '/components/resets.css';
@import '/components/animate.css';
@import '/components/custom-fonts.css';
@import '/components/typography.css';
@import '/components/buttons.css';
@import '/components/colors.css';
@import '/components/form.css';
@import '/components/table.css';
@import '/components/scrollbars.css';
@import '/components/media.css';
@import '/components/navigation.css';
@import './components/pdp-gallery.css';
@import './components/pdp-badge.css';
@import './components/pdp-information.css';
@import '/components/accordion.css';
@import './components/yotpo-star-widget.css';
@import './components/yotpo-reviews-widget.css';
@import '/components/threeup.css';
@import '/components/hotspots.css';
@import '/components/bordered-grid.css';
@import '/layout/header.css';
@import '/layout/discount-banner.css';
@import '/layout/footer.css';
@import '/templates/account.css';
@import './components/product-recommendations.css';
@import './components/parallax-story.css';
@import './components/store-locator.css';
@import '/components/slick.css';
@import 'scroll-snap-slider/src/scroll-snap-slider.css';
@import './components/pdp-buy-with-prime.css';
@import './components/custom-cursor.css';

#__next {
  min-height: 100svh;
  display: flex;
  flex-direction: column;
}

main {
  flex: 1;
}

.main-container {
  @apply before:block before:w-full before:h-full before:pointer-events-none before:opacity-0 before:transition-opacity before:fixed before:bg-white before:z-[100] before:top-0 before:left-0;

  &.is-loading {
    @apply before:fixed before:opacity-100 pointer-events-auto;
  }
}
