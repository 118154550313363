.footer-tagline {
  margin-bottom: 0 !important;
}

#chat-button {
  z-index: 2000 !important;
}

.osano-cm-widget {
  display: none;
}

footer #ot-sdk-btn.ot-sdk-show-settings,
footer #ot-sdk-btn.optanon-show-settings{
  @apply !border-0 block p-0 text-small lg:text-regular lowercase text-white no-underline hover:text-grey-7 hover:underline !bg-transparent !text-left;
}