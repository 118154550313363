.accordion-item {
  @apply w-full flex justify-center relative border-b first:border-t border-primary-1 md:border-l md:border-r;
}
.accordion-item-body {
  @apply w-full max-w-[900px] flex flex-wrap relative;
}
.accordion-item-image-wrapper {
  @apply max-h-0 w-full md:w-[35%] md:max-h-full md:h-full md:absolute top-0 right-0 border-t md:border-t-0 md:border-l border-primary-1 opacity-0 invisible transition-all duration-300 ease-in-out;
}
.accordion-trigger {
  @apply w-4 h-4 md:w-[22px] md:h-[22px] absolute top-7 right-4 md:top-[26px] md:right-10;
  &::before,
  &::after {
    @apply content-[''] w-full h-0.5 absolute top-1/2 left-0 bg-primary-1 -mt-px transition-all duration-300 ease-in-out transform;
  }
  &::before {
    @apply rotate-180;
  }
  &::after {
    @apply -rotate-90;
  }
}
.accordion-item-heading {
  @apply pl-4 py-6 pr-14 lg:pl-10 md:pr-20;
}
.accordion-item-panel {
  @apply overflow-hidden transition-all duration-300 ease-in-out;
}

.accordion-item.active {
  .accordion-trigger::before,
  .accordion-trigger::after {
    @apply rotate-0;
  }
  .accordion-item-image-wrapper {
    @apply opacity-100 visible max-h-full;
  }
}