/*
  SCROLLBARS
*/
.scrollbar {
  @apply overflow-x-auto;
}

.scrollbar::-webkit-scrollbar {
  @apply h-1 w-1;
}

.scrollbar::-webkit-scrollbar-thumb {
  @apply bg-grey-3;
}

.scrollbar::-webkit-scrollbar-track-piece {
  @apply bg-grey-7;
}

.scrollbar--white::-webkit-scrollbar-track-piece {
  @apply bg-opacity-0;
}

.snap-start {
  scroll-snap-align: start;
}

.snap-mandatory {
  scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch;
}

.snap-y-mandatory {
  scroll-snap-type: y mandatory;
}

.snap-center {
  scroll-snap-align: center;
}

.scrollbar-hidden::-webkit-scrollbar { width: 0 !important; height: 0!important; display: none }