/* Slider */
.slick-slider {
  position: relative;

  display: block;

  -moz-box-sizing: border-box;
  box-sizing: border-box;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;

  display: block;
  overflow: hidden;

  margin: 0;
  padding: 0;
}
.slick-list:focus {
  outline: none;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  top: 0;
  left: 0;

  display: block;
}
.slick-track:before,
.slick-track:after {
  display: table;

  content: "";
}
.slick-track:after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  display: none;
  float: left;

  height: 100%;
  min-height: 1px;
}
[dir="rtl"] .slick-slide {
  float: right;
}
.slick-slide img {
  display: block;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
  pointer-events: none;
}
.slick-initialized .slick-slide.slick-active {
  pointer-events: auto;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}
.slick-arrow.slick-hidden {
  display: none;
}
.slick-dots{
  list-style-type: none;
  padding: 0;
  text-align: center;
  margin: 32px 0 0;
}
.category-carousel .slick-dots {
  @apply -mr-4;
}
.product-upsells-container .slick-dots {
  margin-right: 1rem;
  margin-left: -1rem;
}
.slick-dots li{
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  border: 2px var(--grey-1) solid;
  background-color: var(--grey-1);
  margin: 0 5px;
}
.category-carousel .slick-dots li {
  border-color: currentcolor;
  background-color: currentColor;
}
.slick-dots li:last-child{
  background-color: transparent;
}
.slick-dots li.slick-active{
  background-color: transparent;
}
.slick-dots li.slick-active ~ li{
  background-color: var(--grey-1);
}
.category-carousel .slick-dots li.slick-active ~ li{
  background-color: currentColor;
}
.four-up-cta .slick-dots li {
  background-color: currentColor !important;
  border-color: currentcolor;
}
.four-up-cta .slick-dots li.slick-active {
  background-color: transparent !important;
}


.slick-dots li button{
  text-indent: -999em;
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
}
@media (max-width: 767px){
  .slick-dots{
    margin-top: 24px;
  }
}

.section-howto-build-bundle .slick-track {
  display: flex !important;
}
.section-howto-build-bundle--container .slick-track {
  padding-right: 16px;
}

.section-howto-build-bundle .slick-slide {
  height: auto !important;
}

.section-howto-build-bundle .slick-slide > div {
  height: 100%;
}
