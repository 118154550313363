.four-up-cta{
	.slick-dots{
		@apply mt-6 sm:mt-8; 
	}
	div[class="slick-list"]{
		@apply overflow-visible;
	}
	div[class^="slick-track"]{
		@apply flex;
	}
	div[class^="slick-slide"]{
		@apply h-auto;
		> div{
			@apply flex h-full;
		}
	}
}
.ctaContainer{
	&:hover{
		@apply relative before:bg-grey-1;
		&:before{
			@apply w-full h-full opacity-10 absolute left-0 top-0;
		}
		.readmoreLink{
			@apply border-transparent;
		}
	}
	&[class*="text-white"]{
		&:hover{
			@apply before:bg-white;
		}
	}
}