.account-nav {
  @apply text-center lg:text-left lg:max-w-[228px];
  ul {
    @apply list-none p-0 m-0;
    li {
      @apply border-t first:border-t-0 lg:border-t-0 border-grey-7 w-full lg:w-auto py-[10px] px-0 lg:p-0 m-0;
      > a {
        @apply inline-block text-small lg:border-b-2 lg:border-transparent lg:hover:border-grey-1 no-underline mb-0 lg:mb-6;
        &.active {
          @apply lg:border-grey-1 font-gothamBold;
        }
      }
    }
  }
}

.order-history {
  .order-list-header {
    @apply border-b border-grey-1 md-:border-t md-:flex md-:items-center md-:py-5 md-:pl-7 md-:pr-5;
    > div {
      @apply hidden md-:block font-gothamBold;
    }
  }

  .order-item {
    @apply even:bg-grey-9 text-small md-:flex md-:items-center py-7 px-4 md-:px-0 md-:py-4 -mx-4 md-:mx-0;
    .order-item__header {
      @apply flex flex-col md-:flex-row md-:items-center;
      > div {
        @apply flex items-center md-:block justify-between mb-3 md-:mb-0;
      }
    }
    .button {
      @apply inline-block w-full md-:w-auto text-center !py-[7px] md-:px-7 mt-5 md-:mt-0;
    }
  }

  .pagination {
    @apply flex items-center justify-center mt-[60px] md-:mt-12;
    button {
      @apply w-[30px] h-[30px] border border-grey-1 text-grey-1 hover:bg-grey-1 hover:text-white flex items-center justify-center;
      &:disabled {
        @apply pointer-events-none opacity-50;
      }
    }
  }
}

.address-book ul {
  @apply list-none p-0;
  li {
    @apply m-0;
  }
}
.address-item {
  @apply flex flex-col justify-between border border-grey-7 w-full min-h-[296px] px-8 pt-8 pb-10 mb-[22px] md-:mb-6;
}

.account-referral {
  @apply max-w-[480px] flex items-center px-7 lg:px-16;
  .swell-referral-content-sidebar,
  .swell-referral-form-header {
    @apply hidden;
  }
  .swell-referral-content-container {
    @apply text-center p-3;
  }
  .swell-referral-heading {
    @apply font-gothamBold text-[18px] leading-[26px] mb-3;
  }
  .swell-referral-details {
    @apply font-light mb-5;
  }
  .swell-referral-form {
    @apply mb-12 md-:mb-10;
  }
  .swell-referral-form-list {
    @apply list-none p-0 mt-0 mb-5 md-:mb-4;
  }
  .swell-referral-form-field-details {
    @apply font-light text-[12px] leading-normal before:content-['*'] mt-2 md-:mt-1 mb-0;
  }
  .swell-referral-form-list-field-input {
    @apply w-full h-[50px] text-center border border-grey-1 placeholder-shown:border-grey-5 hover:border-grey-3 text-base px-5 focus-visible:outline-none focus:border-2 focus:border-grey-1;
  }
  .swell-referral-form-list-submit {
    @apply button button--secondary w-full;
  }
  .swell-referral-media-wrapper {
    @apply max-w-[290px] mx-auto;
  }
  .swell-referral-media-details {
    @apply font-gothamBold text-small mb-4;
  }
  .swell-referral-media-list {
    @apply list-none flex items-center justify-center p-0 m-0;
    li {
      @apply mx-3 my-0;
    }
  }
  .swell-referral-medium-content {
    @apply text-[0px] leading-[0px];
  }
  .swell-referral-media-icon {
    @apply text-base;
  }
}

.yotpo-widget-subscriptions-customer-portal [data-cy="welcome-text"] {
  @apply !hidden;
}

.yotpo-subscription-portal .yotpo-background {
  @apply !p-0;
}

.yotpo-subscription-portal .yotpo-main-title {
  @apply text-left !text-h3-m md:!text-h3 !font-tiempos mb-2 lg:mb-8;
}

.yotpo-widget-subscriptions-customer-portal .yotpo-layout-multi-product .yotpo-subscription-card {
  padding-inline: 0 !important;
}

.yotpo-widget-subscriptions-customer-portal .yotpo-is-mobile .yotpo-card {
  @apply !py-[20px] !px-[7px];
}

.yotpo-widget-subscriptions-customer-portal .yotpo-billing-attempt-in-progress-banner-container .yotpo-tag {
  @apply !w-[70px];
}

.yotpo-modal-layout .yotpo-popup-button-right,
.yotpo-widget-subscriptions-customer-portal .yotpo-layout-multi-product .yotpo-card,
.yotpo-widget-subscriptions-customer-portal .yotpo-layout-multi-product .yotpo-price-information-text,
.yotpo-widget-subscriptions-customer-portal .yotpo-card .yotpo-action-button {
  @apply !rounded-none;
}

.yotpo-widget-subscriptions-customer-portal .yotpo-action-button .yotpo-dropdown-text,
.yotpo-widget-subscriptions-customer-portal .yotpo-modal-default-button,
.yotpo-widget-subscriptions-customer-portal .yotpo-action-button-text,
.yotpo-widget-subscriptions-customer-portal .yotpo-action-button-confirm,
.yotpo-widget-subscriptions-customer-portal .yotpo-desktop-offer-skip-instead-of-cancel-actions__button,
.yotpo-widget-subscriptions-customer-portal .yotpo-customized-button .yotpo-customized-text,
.yotpo-widget-subscriptions-customer-portal .yotpo-customized-button,
.yotpo-widget-subscriptions-customer-portal .yotpo-cancel-subscription-confirm-button,
.yotpo-widget-subscriptions-customer-portal .yotpo-add-product-button.yotpo-action-button .yotpo-customized-text,
.yotpo-widget-subscriptions-customer-portal .yotpo-status-tag.yotpo-subheader-text,
.yotpo-widget-subscriptions-customer-portal .yotpo-customized-text.yotpo-action-button-text {
  @apply !font-gothamBold !font-normal;
}

.yotpo-widget-subscriptions-customer-portal .yotpo-modal-container,
.yotpo-widget-subscriptions-customer-portal .yotpo-modal-container-scroll-wrapper .yotpo-product-card__name {
  @apply !font-primary;
}

.yotpo-widget-subscriptions-customer-portal .yotpo-desktop-cancellation-reason-option-button__text,
.yotpo-widget-subscriptions-customer-portal .yotpo-catalog-item-title,
.yotpo-widget-subscriptions-customer-portal .yotpo-cancellation-reason-comment__text,
.yotpo-widget-subscriptions-customer-portal .yotpo-date-header,
.yotpo-widget-subscriptions-customer-portal .yotpo-customized-text {
  @apply !font-primary !font-normal;
}

.yotpo-widget-subscriptions-customer-portal .yotpo-main-title,
.yotpo-widget-subscriptions-customer-portal .yotpo-modal-header-text,
.yotpo-widget-subscriptions-customer-portal .yotpo-order-summary-text-wrapper .yotpo-order-summary-text,
.yotpo-widget-subscriptions-customer-portal .yotpo-desktop-offer-skip-instead-of-cancel-title,
.yotpo-widget-subscriptions-customer-portal .yotpo-desktop-cancellation-reason-title,
.yotpo-widget-subscriptions-customer-portal .yotpo-cancel-subscription-desktop__title,
.yotpo-widget-subscriptions-customer-portal .yotpo-modal-wrapper .yotpo-modal-header-text,
.yotpo-widget-subscriptions-customer-portal .yotpo-mobile-drawer-content-title,
.yotpo-widget-subscriptions-customer-portal .yotpo-update-frequency-subscription-title {
  @apply !font-tiempos !font-normal;
}

.yotpo-widget-subscriptions-customer-portal button.yotpo-customized-button.yotpo-cancel-subscription-confirm-button {
  @apply !w-[222px];
}

.bottom-account-gradient {
  height: 50px;
  background-image: linear-gradient(to bottom, rgba(255,255,255,0), rgba(255,255,255,1));
  position: absolute;
  bottom: 0;
  z-index: 1;
  width: 100%;
}

.order-history [yotpo-instance-id="23933"],
.order-history [yotpo-instance-id="23934"],
.order-history [yotpo-instance-id="23936"],
.order-history [yotpo-instance-id="23935"],
.order-history [yotpo-instance-id="23938"] {
  @apply !hidden;
}

.order-history .yotpo-coupons-redemption-widget .yotpo-coupons-redemption-widget-container {
  @apply !pt-6;
}

.order-history .yotpo-coupons-redemption-widget .yotpo-redemption-options-list:not(.yotpo-is-mobile).three-in-row.yotpo-no-background-type,
.order-history .yotpo-coupons-redemption-widget .yotpo-message-display-area {
  @apply !mt-0;
}