.site-header{
	.cart-count{
		@apply w-6 h-6 text-white text-xs bg-grey-1 rounded-full flex items-center justify-center font-gothamBold;
	}
}
.sidecartOpen{
	@apply fixed overflow-visible w-full;
  top: var(--topPosition);
	&:before{
		content: '';
		@apply absolute top-0 left-0 w-full h-full opacity-50 bg-black z-[2001];
	}
}

.header__icons .button--primary,
.header__icons .button--secondary,
.header__icons .button--checkout {
	@apply !px-5 !py-2 lg:min-w-[200px];
}

.header__icons .focus-link {
	@apply lg:min-w-max;
}

.menu-drawer__footer .button--primary,
.menu-drawer__footer .button--secondary,
.menu-drawer__footer .button--checkout {
	@apply min-w-full;
}

body {
  &.show-header .site-header {
    @apply block translate-y-[0] opacity-100;
  }

  &.hide-header .product-sticky-bar {
    @apply top-0;
  }

  &.hide-header .site-header {
    @apply translate-y-[-100%] opacity-0 pointer-events-none;
  }

  &.is-scrolling {
    .site-header {
      @apply fixed;
    }
  }
}
