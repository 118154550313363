.tableBuilder {
	@apply table;
	width: calc(100% *var(--columns));
	@media screen and (min-width: 768px) {
		width: max(calc(372px *var(--columns)), calc(100%/var(--columns)));
	}
	thead {
		@apply bg-transparent;
		th {
			@apply py-5 pl-7 pr-0 bg-grey-1 text-white align-middle text-sm font-gothamBold whitespace-nowrap;
			&:last-child{
				@apply pr-7;
			}
		}
	}
	tbody tr {
		&:nth-child(even) {
			@apply bg-grey-9;
		}
		&:nth-child(odd) {
			@apply bg-white;
		}
		td {
			@apply py-11 pl-7 pr-0 text-sm;
			&:last-child{
				@apply pr-7;
			}
		}
	}
}
