.main-product-title {
  margin-bottom: 24px !important;
}

.product-size-disabled {
  background: linear-gradient(
    to top left,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0) calc(50% - 1px),
    rgba(223, 223, 223, 1) 50%,
    rgba(0, 0, 0, 0) calc(50% + 1px),
    rgba(0, 0, 0, 0) 100%
  );
  @apply relative outline-grey-4;
}

.product-size-active.product-size-disabled {
  @apply bg-grey-9 outline-black;
}

.product-upsell-prices .line-through {
  @apply !text-black decoration-1;
}

.product-main-container [data-comp="productupsells"] {
  @apply !hidden lg:!block lg:!mt-[24px];
}

[data-comp="productupsells"] {
  @apply block m-auto max-w-[642px] lg:hidden;
}

.inner-product-upsells-container {
  @apply mt-[24px] lg:mt-0 p-6 pt-1 lg:p-0 lg:pt-0;
}

.quickview-modal-content-container .product-promo-message,
.quickview-modal-content-container .product-discount-message {
  @apply hidden;
}