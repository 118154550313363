.bordered-grid-body {
  @apply text-[14px] leading-[22px] md-:text-[16px] md-:leading-normal;
  > * {
    @apply last:mb-0;
  }
  p {
    @apply font-light mb-3;
  }
  ul,
  ol {
    @apply pl-5 mt-0 mb-3;
    li {
      @apply m-0;
    }
  }
}

.bordered-grid-icons {
  @apply flex;
}
.bordered-grid-icon {
  @apply flex-grow basis-0 text-center;
}