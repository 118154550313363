.pdp-slider{
  @apply w-full lg:w-full relative;
  .slick-initialized{
    width: 100%;
  }
  .pdp-slider-slide {
    @apply block w-auto mx-0.5 md:mx-auto !relative bg-grey-9;
  }
  .pdp-slider-slide--video {
    @apply relative;
    &:before {
      content: '';
      position: absolute;
      background-color: rgba(84, 88, 90, 0.35);
      top: -1px;
      bottom: -1px;
      left: -1px;
      right: -1px;
      opacity: 1;
      pointer-events: none;
    }
    &:after {
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      content: '\25b6\fe0e';
      display: block;
      color: #fff;
      width: 60px;
      height: 60px;
      background: transparent;
      border: 2px solid #fff;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      font-size: 21px;
      padding-left: 5px;
      pointer-events: none;
    }
  }
  .fslightbox-thumb-active{
    @apply !border-secondary-1;
  }
  .slick-list {
    @apply mb-4 lg:mb-0;
  }
  .slick-dots{
    @apply w-full m-0 p-0 text-center mb-6;
    font-size: 0;
    line-height: 1;

    .builder__quick-view & {
      @apply !hidden;
    }

    li{
      @apply inline-block !border-0 w-2 h-2 m-0 ml-3 first:ml-0 opacity-40;
      button{
        text-indent: -999em;
        @apply bg-black w-2 h-2 rounded-full;
      }
      &.slick-active{
        @apply opacity-100
      }
    }
  }
  .slick-arrow{
    @apply absolute top-1/2 -translate-y-1/2 z-1 p-4;
    &.slick-next{
      @apply right-0;
    }
  }
  .fslightbox-slide-number-container{
    @apply hidden;
  }
  .fslightbox-toolbar-button{
    @apply hidden last:flex;
  }
}

.pdp-thumbnails-slider-next{
  @apply pr-[5px];
}

.pdp-thumbnails-slider,
.bb-qv-thumbnails-slider {
  @apply w-full overflow-hidden;
  [class*="slick-arrow"]{
    @apply items-center justify-center hover:bg-black hover:text-white hidden md:flex;
  }
  .slick-initialized{
    @apply px-2 lg:px-0 md:pb-14;

    .builder__quick-view & {
      @apply pl-0 lg:pl-2;
    }
  }

  .slick-list {
    @apply pt-[59%] sm:pt-[39%] lg:pt-0 before:block before:border-b before:border-grey-1 before:absolute before:bottom-0 before:left-[1px] before:z-1 md:before:hidden;
    &:before {
      width: calc(100% - 2px);
    }
  }
  [class*="slick-arrow"]{
    @apply items-center justify-center hover:bg-black hover:text-white hidden md:flex;
  }

  .pdp-thumbnails-image--video{
    @apply relative;
    &:before {
      content: '';
      position: absolute;
      background-color: rgba(84, 88, 90, 0.35);
      top: -1px;
      bottom: -1px;
      left: -1px;
      right: -1px;
      opacity: 1;
      pointer-events: none;
    }
    &:after {
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      content: '\25b6\fe0e';
      display: block;
      color: #fff;
      width: 60px;
      height: 60px;
      background: transparent;
      border: 2px solid #fff;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      font-size: 21px;
      padding-left: 5px;
    }
  }
  .slick-slide{
    .pdp-thumbnails-image{
      @apply border border-grey-1;
      &:hover{
        @apply border-primary-2;
      }
    }

    &.slick-current{
      .pdp-thumbnails-image{
        @apply border-primary-2;
      }
    }
  }
  &.pdp-thumbnails-fix{
    .slick-track{
      @apply !h-auto;
    }
  }
}

.bb-qv-thumbnails-slider {
  .slick-list {
    @apply before:border-b-0 before:hidden pt-0;
  }
}