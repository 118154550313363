/*
  TABLES
*/
.table-wrapper {
  @apply overflow-auto;
}

.table-wrapper > :last-child {
  @apply mb-0;
}

table {
  @apply border border border-black mb-8;
}

thead {
  @apply bg-black;
}

thead td {
  @apply py-4 px-6 text-[1rem] leading-[24px] text-white font-gothamBold;
}

tbody td {
  @apply py-6 px-6 min-w-[12.5rem] text-black text-[1rem] leading-[24px];
}

tbody tr {
  @apply bg-grey-9;
}

tbody tr:nth-child(2n - 1) {
  @apply bg-white;
}

td {
  @apply border-r border-black;
}
