.yotpo-badge-container {
  @apply mb-4;

  .yotpo-bottomline {
    @apply !gap-1 items-center;
  }

  .yotpo .yotpo-bottomline .yotpo-icon {
    @apply !text-black;
  }

  .yotpo .yotpo-bottomline .text-m {
    @apply !underline font-primary text-xsmall text-black cursor-pointer leading-none;
  }
}

.yotpo-main-widget {
  @apply pt-0 pr-[20px] pb-[30px] pl-[20px] outline-0 max-w-[850px] my-0 mx-auto md:pb-[50px];
}

#pdp-yotpo-reviews-widget {
  @apply !pt-0 p-6 lg:px-6 w-[80%] lg:w-full my-0 mx-auto lg:max-w-[1118px] lg:m-auto;
  &.yotpo div,
  &.yotpo span,
  &.yotpo p,
  &.yotpo a,
  &.yotpo img,
  &.yotpo i,
  &.yotpo strong,
  &.yotpo sup,
  &.yotpo ul,
  &.yotpo li,
  &.yotpo form,
  &.yotpo label {
    @apply font-primary;
  }
  .yotpo-label-container {
    @apply !hidden;
  }

  .bottom-line-items-wrapper {
    @apply w-full relative flex flex-col justify-between md:flex-row-reverse;
  }

  .yotpo-regular-box {
    @apply !h-auto !flex-col md:!flex-row !p-0 !m-0;
  }

  .yotpo-star-distribution-wrapper {
    @apply !p-0;
    height: unset !important;
  }

  .yotpo-distribution-rows {
    @apply justify-center mb-[24px] md:mb-0;
  }

  .yotpo-distribution-row {
    @apply flex !h-[33px] justify-center;
    width: 350px!important;

    @media screen and (max-width: 400px) {
      width: 300px !important;
    }
  }

  .yotpo-star-distribution-score-value {
    @apply text-black flex !w-auto !font-gothamBold !text-regular;

    &:after {
      content: 'Stars';
      display: inline-block;
      margin: 0 15px 0 5px;
    }
  }

  .yotpo-star-distribution-bar {
    height: 12px !important;
    width: 100% !important;
    border-radius: 999px;
  }

  .yotpo-star-distribution-bar-score {
    height: 12px !important;
    @apply !bg-quinary-2;
    border-radius: 999px;
    min-width: 15px;
  }

  .bottom-line-items {
    @apply !pr-0 flex justify-center !top-0 p-0 !w-full md:block md:!h-auto md:!w-auto;
    height: unset !important;
  }

  .write-question-review-buttons-container {
    @apply !pr-0 flex justify-center !top-0 p-0 !w-full md:block md:!h-auto md:!w-auto mb-[24px] md:mb-0;
  }

  .avg-score {
    display: none !important;
  }

  .rating-stars-container {
    margin: 0 !important;
    padding: 0 !important;
  }

  .bottom-line-only-container {
    min-width: 190px;
    margin-left: 0;
    margin-top: 0;
    display: flex !important;
    flex-direction: column;
    align-items: center;

    * {
      padding-inline-start: 0 !important;
      left: 0;
    }
  }

  .reviews-qa-labels-container {
    width: 100%;
    position: relative !important;
    display: inline-block;
    float: none !important;
    margin: 0 !important;
    padding: 0 !important;
    line-height: 1.5;
  }

  .reviews-qa-label {
    font-size: 16px !important;
    font-weight: normal !important;
    width: 100% !important;
    @apply !font-primary text-black;
    padding: 0 !important;
    text-align: center;
    margin: 0 !important;
    top: 0 !important;
    bottom: 0 !important;
  }

  .yotpo-filter-stars.rating-stars-container {
    display: flex;
    justify-content: center;
    top: 0 !important;
    padding-inline-start: 0 !important;

    .yotpo-icon {
      font-size: 20px;
      height: 20px;
      line-height: 1;
      margin-bottom: 20px;
    }
  }

  .write-question-review-buttons-container {
    @apply !top-0 md:!top-[-70px] float-none md:float-right; 
    display: flex !important;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
    position: relative;
    width: 100%;
    align-items: center;

    .yotpo-icon-btn {
      width: 190px;
      padding: 12px 10px;
      border-width: 2px!important;
      @apply !border-black hover:!bg-black hover:!text-white;
      border-radius: 0 !important;
      font-size: 12px !important;
      text-transform: uppercase;
      margin-left: 0 !important;
      transition: color 150ms ease-in-out;
      line-height: 14px !important;
      height: 44px !important;

      &:hover {
        span {
          @apply text-white;
        }
      }
    }

    .yotpo-icon {
      display: none !important;
    }
  }

  .new-yotpo-small-box {
    margin-top: 0;
    border-bottom: 0;
    width: 100%;
    overflow: hidden;

    .yotpo-nav {
      display: flex;
      flex-direction: row;

        width: 100%;
        padding-top: 40px;
        align-items: center;
        justify-content: center;
    }

    .yotpo-nav-wrapper {
      text-transform: lowercase !important;
      span {
        font-size: 16px;
        text-transform: lowercase !important;
        font-weight: normal;
        @apply text-black font-gothamBold;
      }
    }

    .yotpo-active .yotpo-nav-wrapper {
      @apply border-black;
      span {
        @apply text-black;
      }
    }
  }


  .filters-container {
    display: flex;
    flex-direction: column;
  }
  .free-text-search {
    order: 9;
    @apply bg-grey-9 py-[40px] px-[20px] md:px-[35px];
    margin-top: 20px;
    width: 100%;
    display: flex;
    align-items: center;

    &:before {
      content: 'Search for reviews';
      display: block;
      width: 300px;
      margin-right: 155px;
      margin-right: auto;
     @apply text-black font-gothamBold !font-normal hidden md:block;
      font-size: 18px;
      font-weight: 700;
    }

    input {
      margin: 0;
      @apply bg-white;
      border-radius: 0;
      padding: 5px 45px 5px 5px;

      width: 100%;
      border: 1px solid;
    }
    .yotpo-icon-magnifying-glass {
      display: flex;
      justify-content: center;
      align-items: center;
      left: unset;
      right: 20px;
      top: 50%;
      width: 40px !important;
      height: 40px;
      @apply text-white bg-black;
      transform: translateY(-50%);
    }
  }

  .suggested-topic {
    border-radius: 0;
    height: auto;
    padding: 3px 7px;
    @apply bg-quinary-2;
    font-weight: 700 !important;

    &:hover,
    &.active-topic {
      @apply bg-grey-9;
    }
  }

  .suggested-topic-text {
    padding: 0 !important;
    @apply font-gothamBold text-black;
    font-size: 12px;
    line-height: 1.5;
  }

  .yotpo-review,
  .yotpo-question {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .yotpo-header {
        width: 150px;
        order: 2;

      .yotpo-review-stars {
        order: -1;
        margin-top: 0;
        @apply mb-[40px] md:mb-[15px];
      }

      .yotpo-header-element {
        display: flex;
        flex-direction: column;
        &.yotpo-header-actions {
          float: none;
          order: 1;
          flex-direction: row;
          .yotpo-review-date {
            @apply text-black text-regular;
          }
          &:before {
            content: 'Submitted on ';
            margin-right: 5px;
            @apply text-black text-regular font-gothamBold;
          }
        }
      }

      .yotpo-user-name {
        @apply text-black text-regular font-normal;

        &:before {
          content: 'By: ';
          @apply text-black font-gothamBold text-regular font-normal;
        }
      }

      .yotpo-user-title {
        margin-top: 8px;
        margin-bottom: 8px;
        @apply text-black text-regular font-gothamBold;
      }

      .yotpo-icon-profile {
        display: none;
      }
    }

    .yotpo-user-related-fields {
      display: none;
    }

    .yotpo-main {
      margin-left: 0;
      display: flex;
      flex-direction: column;
      position: relative;

      @media screen and (max-width: 768px) {
        width: 100% !important;
      }

        order: 1;
        width: calc(100% - 200px);
        padding-right: 40px;
        margin-top: 0;
      

      .yotpo-user-related-fields {
        display: block;
      }

      .content-title {
        font-size: 16px;
        @apply text-black md:top-0 relative font-gothamBold font-normal text-regular;
        margin-bottom: 15px;
        padding-bottom: 0;
      }

      .content-question {
        font-size: 16px;
        @apply text-black;
        margin-bottom: 0;
        padding-bottom: 0;
      }

      .content-review {
        @apply text-black text-regular;
      }
    }

    .yotpo-verified-user ~ .yotpo-main .content-title {
      @apply text-black relative top-0;
    }

    .yotpo-verified-buyer {
      width: 200px !important;
    }

    .yotpo-multiple-rating-fields {
      margin-top: 0;
      order: 9;

      .yotpo-related-user-fields {
        margin-bottom: 0;
      }
    }

    .yotpo-user-field-description {
      font-style: normal !important;
      @apply text-black;
    }

    .yotpo-footer {
      margin-left: 0;
      display: flex;
      order: 3;

      @apply mt-2 md:mt-0;

      .footer-actions {
        display: none !important;
      }
      .footer-additional-actions {
        display: none;
      }

      .y-label.label-helpful {
        font-size: 11px;
        @apply text-black;
      }
    }

    .yotpo-comments-box {
      border-left: 0;
      order: 999;
      margin-left: 0;
      @apply bg-grey-9;
    }

    .yotpo-comment-box {
      margin-left: 0;
      padding: 20px !important;

      .yotpo-main {
        margin-bottom: 0;
      }
    }
  }

  .write-review {
    @apply mt-[30px] md:mt-0;

    .yotpo-field-title {
      border: none;
    }
  }

  .yotpo-first-review {
    .yotpo-icon-star {
      @apply text-black;
    }

    .yotpo-default-button,
    .write-first-review-button {
      height: 44px;
      @apply font-gothamBold leading-none lowercase text-regular text-black border-solid border-2 border-black !bg-white hover:!bg-black hover:text-white;
    }
  }

  #pdp-yotpo-reviews-widget .yotpo-first-review .write-first-review-button {
    @apply font-gothamBold lowercase text-regular text-black border-solid border-2 border-black !bg-white !max-w-[345px] md:!max-w-[318px];
  }

  input.yotpo-default-button.primary-color-btn.yotpo-submit {
    @apply font-gothamBold lowercase text-regular text-white;
  }

  .yotpo-messages {
    @apply mt-[30px] mb-0 md:mt-[60px] md:mb-[20px];

    .yotpo-thankyou-header {
      width: 100%;
      text-align: center;
    }
  }
  .write-question-review-buttons-container .write-question-review-button .write-question-review-button-text {
    @apply font-gothamBold lowercase font-normal !w-full text-black;
  }

  .suggested-topics-row {
    margin-bottom: 24px !important;
  }

  .yotpo-icon-btn-small.transparent-color-btn.vote-btn {
    @apply ml-0 md:ml-2;
  }

  span.y-label.yotpo-sum.vote-sum {
    @apply mr-2 md:mr-0;
  }
}

#pdp-yotpo-reviews-widget {
  .yotpo-base-layout {
    @apply lg:!w-auto;
  }
}

.yotpo .yotpo-bottomline .yotpo-icon-star, .yotpo .yotpo-bottomline .yotpo-icon-half-star, .yotpo .yotpo-bottomline .yotpo-icon-empty-star {
  @apply !text-black;
}

h2.y-label.yotpo-header-title {
  @apply font-primary text-regular text-black;
  padding: 20px 7px 15px 0px !important;
}

textarea#yotpo_input_question_content,
input#yotpo_input_question_username,
.yotpo .write-review .connect-wrapper .form-element .y-input,
.yotpo .y-input,
#pdp-yotpo-reviews-widget .free-text-search input {
  @apply focus:outline-ada-outline;
}

.yotpo .write-review .yotpo-header .yotpo-icon,
.yotpo span.yotpo-mandatory-mark,
.yotpo .yotpo-review .yotpo-header .yotpo-review-stars .yotpo-icon, .yotpo .yotpo-comment .yotpo-header .yotpo-review-stars .yotpo-icon, .yotpo .yotpo-question .yotpo-header .yotpo-review-stars .yotpo-icon, .yotpo .yotpo-onsite-upload .yotpo-header .yotpo-review-stars .yotpo-icon,
.filters-dropdown .yotpo-dropdown-button .selected span,
.filters-dropdown .yotpo-dropdown .list-category .selected-stars,
.filters-dropdown .yotpo-dropdown .list-category .non-selected-star {
  @apply !text-black;
}

#pdp-yotpo-reviews-widget .suggested-topic {
  padding: 12px 22px;
}

#pdp-yotpo-reviews-widget span.suggested-topic {
  margin-right: 18px;
}

#pdp-yotpo-reviews-widget span.suggested-topic-text,
#pdp-yotpo-reviews-widget .filters-container-title {
  @apply !font-gothamBold !text-black font-normal;
}

#pdp-yotpo-reviews-widget .filters-dropdown .yotpo-dropdown-button {
  border-radius: 0 !important;
  @apply !border-black;
}

#pdp-yotpo-reviews-widget .yotpo-reviews-filters {
 @apply !m-0;
}

#pdp-yotpo-reviews-widget .bottom-line-items-wrapper .yotpo-filter-stars.rating-stars-container .yotpo-icon {
  font-size: 24px;
}

#pdp-yotpo-reviews-widget .main-widget .yotpo-reviews-filters {
  @apply border-black border-2;
}

#pdp-yotpo-reviews-widget .yotpo-regular-box {
  @apply !border-black;
  padding-top: 24px !important;
  padding-bottom: 24px !important;
}

#pdp-yotpo-reviews-widget .main-widget .yotpo-reviews-header {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

#pdp-yotpo-reviews-widget .main-widget .yotpo-reviews-header .reviews-amount {
  @apply font-gothamBold text-grey-5 font-normal;
}

#pdp-yotpo-reviews-widget .yotpo-review .yotpo-user-field-description {
  @apply !text-black !font-gothamBold !text-regular !font-normal;
}

#pdp-yotpo-reviews-widget .yotpo-review .yotpo-user-field-answer,
#pdp-yotpo-reviews-widget span.y-label.question-date {
  @apply text-black text-regular font-normal;
}

#pdp-yotpo-reviews-widget .yotpo-user-field {
  margin-bottom: 8px;
}

#pdp-yotpo-reviews-widget .yotpo-grouping-reference {
  display: none;
}

#pdp-yotpo-reviews-widget .yotpo-review .yotpo-footer .y-label.label-helpful,
#pdp-yotpo-reviews-widget .yotpo-question .yotpo-footer .y-label.label-helpful {
  @apply !text-small;
}

#pdp-yotpo-reviews-widget .yotpo-review .yotpo-header,
#pdp-yotpo-reviews-widget .yotpo-question .yotpo-header {
  width: 200px;
}

#pdp-yotpo-reviews-widget .yotpo-review .yotpo-comment-box .yotpo-main,
#pdp-yotpo-reviews-widget .no-border.yotpo-comments-box.visible,
#pdp-yotpo-reviews-widget .yotpo-comment-box.yotpo-comment  {
  width: 100%;
}

#pdp-yotpo-reviews-widget .yotpo-question .yotpo-footer .y-label.label-helpful {
  margin-top: 24px;
}

#pdp-yotpo-reviews-widget .yotpo-review .yotpo-header .yotpo-review-stars {
  @media screen and (max-width: 768px) {
    margin-bottom: 12px;
  }
}

.main-widget.yotpo-display-wrapper.yotpo-no-reviews {
  @apply flex flex-col items-center justify-center;
}

#pdp-yotpo-reviews-widget .yotpo-no-reviews .write-question-review-buttons-container {
  @apply !top-0;
}

#pdp-yotpo-reviews-widget .yotpo-no-reviews .yotpo-messages {
  @apply mt-[10px] mb-[10px];
}

#pdp-yotpo-reviews-widget .yotpo-no-reviews .write-review-wrapper.write-form.visible {
  @apply mt-[20px] mb-0;
}

#pdp-yotpo-reviews-widget div.yotpo-preloader-wrapper .yotpo-pre-loader .yotpo-icon {
  @apply text-black;
}

.yotpo-badge-container a.text-m {
  @apply !text-black;
}

#pdp-yotpo-reviews-widget .sort-drop-down .yotpo-dropdown-button span {
  @apply !font-gothamBold !text-black;
}

.yotpo-reviews-main-widget .yotpo-main-widget-tabs .ugc-storefront-widgets-tabs-container__tab {
  @apply !font-gothamBold !text-black !font-normal border-white !border-t-0 !border-r-0 !border-b-2 !border-l-0;
}

.yotpo-reviews-main-widget .yotpo-main-widget-tabs .ugc-storefront-widgets-tabs-container__tab.selected {
  @apply !border-b-2 border-black;
}

.yotpo-questions-and-answers #yotpo-widget-btn.yotpo-question-btn,
.yotpo-reviews-main-widget #yotpo-main-widget-btn.yotpo-new-review-btn {
  @apply !no-underline !font-normal !text-black;
}

.yotpo-question .yotpo-question-content,
.yotpo-read-more-text,
.yotpo-modal-header,
.yotpo-form-label,
.yotpo-custom-question-headline,
.yotpo-qna-modal-header-font {
  @apply !font-normal !text-black;
}

.yotpo-reviews-main-widget #yotpo-main-widget-btn.yotpo-new-review-btn {
  @apply !no-underline !font-normal hover:!text-white;
}

.yotpo-questions-and-answers #yotpo-widget-btn.yotpo-question-btn span,
.yotpo-review-title,
.yotpo-smart-topics-see-more-label,
.yotpo-title-text {
  @apply !font-normal;
}

.yotpo-horizontal-pagination .yotpo-pagination-item {
  @apply !h-auto;
}

.yotpo-horizontal-pagination {
  @apply items-center;
}

.yotpo-empty-state {
  @apply flex flex-col items-center;
}

.yotpo-reviews-main-widget div:empty {
  @apply !bg-none !mt-0;
}

.yotpo-new-review-btn.empty-state {
  @apply px-2;
}

#newReviewFormModal {
  @apply z-[9999];
}

.yotpo-questions-and-answers:not(.yotpo-device-mobile) .yotpo-header {
  @apply !flex-row !items-center !justify-center;
}

.yotpo-input-wrapper textarea,
.yotpo-new-input-container {
  @apply border !border-black;
}

.yotpo-qna-modal-backdrop {
  z-index: 19999 !important;
}
