.category-carousel{
	button svg{
		@apply scale-75;
	}
	.category__image{
		padding-bottom: 120.75%;
	}
	.slick-dots{
		@apply mt-6 sm:mt-8;
	}
	div[class="slick-list"]{
		@apply overflow-visible;
	}
}
.category{
	@apply w-full;
	@media screen and (min-width: 769px) {
		&[class*="text-white"]:hover{
			.category__title{
				@apply text-grey-1 bg-white;
			}
		}
		&:hover{
			.category__title{
				@apply text-white bg-grey-1;
			}
			.category__image > img.category__hoverImage{
				@apply visible;
				~ img{
					@apply invisible;
				}
			}
		}
	}
}

.category-grid {
  width: calc(50% - 8px);
}

@media screen and (min-width: 769px) {
  .category-grid {
    width: calc(33.3333% - 11px);
  }
}