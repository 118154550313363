@font-face {
  font-family: 'Tiempos';
  src: url("../fonts/TiemposHeadline-Light.woff");
}

@font-face {
  font-family: 'Gotham';
  src: url("../fonts/Gotham-Book.woff");
}

@font-face {
  font-family: 'Gotham Bold';
  src: url("../fonts/Gotham-Bold.woff");
}