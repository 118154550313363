/*
  INPUTS
*/
input[type="radio"],
input[type="checkbox"] {
  @apply relative border border-black bg-white appearance-none cursor-pointer transition-all;
}

input[type="radio"][disabled],
input[type="checkbox"][disabled] {
  @apply border-grey-5  bg-grey-1;
}

input[type="radio"]:focus-visible,
input[type="checkbox"]:focus-visible,
input[type="radio"]:active,
input[type="checkbox"]:active,
button:focus-visible {
  @apply outline-ada-outline;
}

input[type="checkbox"]:checked {
  @apply border-0 bg-black text-white;
}

input[type="radio"] {
  @apply rounded-full w-6 h-6;
}

input[type="radio"]::before {
  content: '';
  @apply absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 rounded-full w-0 h-0 bg-black transition-all;
}

input[type="radio"]:checked::before {
  @apply w-3.5 h-3.5;
}

input[type="radio"] + label,
input[type="checkbox"] + label {
  @apply cursor-pointer;
}

input[type="checkbox"] {
  @apply w-6 h-6;
}

input[type="checkbox"]:checked::after {
  content: url('data:image/svg+xml; utf8, <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M9.8033 15.2353L18.2886 6.75L19.3492 7.81066L9.8033 17.3566L4.5 12.0533L5.56066 10.9926L9.8033 15.2353Z" fill="white"/></svg>');
  @apply absolute top-0 left-0 text-white;
}

select {
  @apply appearance-none;
  @apply focus:outline-ada-outline border border-grey-5 placeholder-shown:border-grey-5 hover:border-black py-3 px-4 w-full placeholder-grey-5 /* text-grey-5 */ hover:placeholder-black focus:placeholder-black cursor-pointer transition-all;
}

.select-wrap-2::after {
  touch-action: none;
  content: url('data:image/svg+xml; utf8, <svg width="15" height="9" viewBox="0 0 15 9" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 1L7.5 7.5L14 1" stroke="%23555759" stroke-width="3" stroke-linecap="round"/></svg>');
	  @apply absolute bottom-[10px] scale-[.65] right-[-2px] w-[6px] h-[6px] pointer-events-none flex items-center justify-center border-grey-5 transition-all;
}
.select-wrap::after {
  touch-action: none;
  content: url('data:image/svg+xml; utf8, <svg width="15" height="9" viewBox="0 0 15 9" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 1L7.5 7.5L14 1" stroke="%23555759" stroke-width="2" stroke-linecap="round"/></svg>');
	  @apply absolute bottom-0 right-0 w-[50px] h-[50px] pointer-events-none flex items-center justify-center border-l border-grey-5 transition-all;
}
.select-wrap-3::after {
  touch-action: none;
  content: url('data:image/svg+xml; utf8, <svg width="15" height="9" viewBox="0 0 15 9" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 1L7.5 7.5L14 1" stroke="%23555759" stroke-width="2" stroke-linecap="round"/></svg>');
	  @apply absolute bottom-0 right-0 w-[40px] h-[40px] pointer-events-none flex items-center justify-center border-l border-grey-5 transition-all;
}
.select-wrap-4::after {
  touch-action: none;
  content: url('data:image/svg+xml; utf8, <svg width="10" height="9" viewBox="0 0 15 9" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 1L7.5 7.5L14 1" stroke="white" stroke-width="2" stroke-linecap="round"/></svg>');
	  @apply absolute bottom-0 right-0 w-[32px] h-[100%] pointer-events-none flex items-center justify-center border-l border-grey-5 transition-all;
}

.select-wrap:hover{
  &:after {
    @apply border-black;
  }
}

.form-container {
  input:not([type=checkbox]) + label,
  select + label,
  textarea + label {
    @apply absolute top-1 left-5 sub-xsmall pointer-events-none transition-all duration-200;
  }
  input:not([type=checkbox]):not(:focus):placeholder-shown + label,
  select:not(.selected) + label,
  textarea:not(:focus):placeholder-shown + label {
    @apply sub-small top-4;
  }
}

.form-container {
  @apply relative;
}

.form-field {
  @apply relative;
  input:not([type=checkbox]) + label,
  select + label,
  textarea + label {
    @apply absolute left-[21px] top-1 text-[11px] font-gothamBold pointer-events-none transition-all duration-200;
  }
  input:not([type=checkbox]):not(:focus):placeholder-shown,
  select:not(.selected),
  textarea:not(:focus):placeholder-shown {
    @apply border-grey-5 hover:border-grey-3 focus:border-grey-1;
    & + label {
      @apply top-[13px] font-primary text-base;
    }
  }
  input:not([type=checkbox]),
  textarea,
  select {
    @apply w-full h-[50px] border border-grey-1 text-base px-5 pt-[19px] pb-[5px] focus-visible:outline-none focus:border-2 focus:px-[19px] focus:pt-[18px] focus:pb-1;
    &.has-error {
      @apply border-error;
    }
  }
  .error {
    @apply flex items-center text-error text-[14px] leading-5 font-light mt-3 mb-[-5px];
    &::before {
      @apply content-['!'] block w-4 h-4 rounded-full bg-error text-white text-[12px] leading-4 font-normal text-center mr-2;
    }
  }
}
