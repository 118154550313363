@layer utilities {
	.ccontain {
		@apply mx-auto px-6 max-w-[85rem] w-full;
	}
	.container{
		@apply w-full max-w-container mx-auto;
	}
  .left-right-rotate {
    animation: left-right-rotate 3s ease-in-out infinite both;
  }
}
@layer base {
	.w-78{
		width: 312px;
	}
	.w-25{
		width: 100px;
	}
	.w-29{
		width: 116px;
	}
	.pb-full{
		padding-bottom: 100%;
	}
	.z-1{
		z-index: 1;
	}
	.z-2{
		z-index: 2;
	}
	.z-101{
		z-index: 101;
	}
	.w-13{
		width: 52px;
	}
	.h-13{
		height: 52px;
	}
	.w-15\.5{
		width: 62px;
	}
	.h-15\.5{
		height: 62px;
	}
	.pb-4upcta{
		padding-bottom: 68.88%;
	}
	.border-currentColor{
		border-color: currentColor;
	}
}
