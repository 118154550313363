.text-pink {
  @apply text-primary-2;
}

.text-pink-2 {
  @apply text-secondary-1;
}

.text-blue-1 {
  @apply text-quinary-1;
}

.text-blue-2 {
  @apply text-quinary-2;
}

.text-blue-2-alt {
  @apply text-quinary-2-alt;
}

.text-green {
  @apply text-quaternary-1;
}

.text-orange {
  @apply text-secondary-2;
}

.text-cream {
  @apply text-tertiary-2;
}

.text-seafoam {
  @apply text-quaternary-2;
}

.text-purple {
  @apply text-tertiary-1;
}

.text-brown {
  @apply text-senary-4;
}