/*
  BUTTONS
*/
.button {
  @apply border-2 px-5 py-2.5 lg:py-3 font-gothamBold transition-colors duration-200  text-[14px] lg:text-base;
}

.button--small {
  @apply border-2 px-8 p-1 font-gothamBold transition-colors duration-200  text-[14px] lg:text-base;
}

.button--primary {
  @apply min-w-[200px] text-center border-button-primary-bg bg-button-primary-bg text-white hover:bg-button-primary-bg-hover hover:border-button-primary-bg-hover;
}

.button--primary-stylers-1 {
  @apply min-w-[200px] text-center border-stylers-1 bg-stylers-1 text-white hover:bg-stylers-1 hover:border-stylers-1;
}

.button--primary-stylers-2 {
  @apply min-w-[200px] text-center border-stylers-2 bg-stylers-2 text-white hover:bg-stylers-2 hover:border-stylers-2;
}

.button--primary-stylers-3 {
  @apply min-w-[200px] text-center border-stylers-3 bg-stylers-3 text-white hover:bg-stylers-3 hover:border-stylers-3;
}

.button--primary-stylers-4 {
  @apply min-w-[200px] text-center border-stylers-4 bg-stylers-4 text-white hover:bg-stylers-4 hover:border-stylers-4;
}

.button--secondary {
  @apply min-w-[200px] text-center border-black bg-transparent text-black hover:bg-button-outline-bg-hover hover:text-white;
}

.button--tertiary {
  @apply min-w-[200px] text-center border-white bg-transparent text-white hover:bg-white hover:text-black hover:border-white;
}

.button--white {
  @apply border-white bg-white text-black hover:bg-grey-7 hover:border-grey-7;
}

.button--outline {
  @apply border-black bg-white text-black hover:bg-button-outline-bg-hover hover:text-white;
}

.button--outline-dark {
  @apply border-white bg-black text-white hover:bg-white hover:text-black hover:border-white;
}

.button--outline-alt {
  @apply border-white bg-transparent text-white hover:bg-white hover:text-black hover:border-white;
}

.button--atc {
  @apply border-button-atc-bg bg-button-atc-bg text-white hover:bg-button-atc-bg-hover hover:border-button-atc-bg-hover;
}

.button[disabled] {
  @apply border-grey-7 bg-grey-7 text-black;
}

.button--checkout {
  @apply border-button-checkout-bg bg-button-checkout-bg text-white hover:bg-button-checkout-bg-hover hover:border-button-checkout-bg-hover;
}