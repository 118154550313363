figure {
  @apply m-0;
}
figcaption{
	@apply text-xs mt-3;
}

img,
picture {
  @apply m-0 max-w-full;
}

img[data-sizes="auto"] {
  @apply block w-full;
}
.responsive-image{
 @apply block w-full h-0 overflow-hidden relative;
 img,
 model-viewer{
 	width: 100%;
 	object-fit: cover;
  height: 100%;
  position: absolute;
 }
}
.responsive-video {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  width: 100%;
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
.pb-vimeo{
	padding-bottom: 56.25%;
}
.pb-youtube{
	padding-bottom: 56.25%;
}