.story-container {
  position: relative;
  width: 4px;
  margin-left: 20px;
  @apply before:absolute before:top-0 before:left-0 before:w-5 before:h-1 before:transition-colors before:duration-1000 before:opacity-0 before:bg-quinary-1;

  @media screen and (min-width: 769px) {
    width: 4px;
    margin: 0 auto;
    padding-bottom: 180px;
  }

  .storyBar {
    opacity: 0;
  }

  .story-inner-container {
    padding-bottom: 70px;
  }

  &:last-child {
    margin-bottom: 70px;
  }

  &:last-child .story-inner-container {
    padding-bottom: 0;
  }
}

.story-container.in-view {
  @apply before:opacity-100;

  .storyBar {
    opacity: 1;
  }
}

.story-inner-container {
  position: relative;
  bottom: 0;
  width: 288px;
  padding-left: 61px;
  margin-top: -7px;

  @media screen and (min-width: 600px) {
    width: 545px;
  }

  @media screen and (min-width: 769px) {
    padding-left: 52px;
    width: 288px;
  }

  @media screen and (min-width: 1024px) {
    width: 400px;
  }
}

.story-container img {
  opacity: 25%;
}

.story-container.in-view img {
  opacity: 1;
}

.story-container:nth-child(odd) img {
  position: relative;

  @media screen and (min-width: 769px) {
    transform: rotate(-15deg);
    position: absolute;
    top: -5px;
    left: -322px;
    height: 316.45px;
  }

  @media screen and (min-width: 1024px) {
    transform: rotate(-15deg);
    position: absolute;
    top: -60px;
    left: -445px;
    height: 316.45px;
  }
}

.story-container:nth-child(even) img {
  position: relative;

  @media screen and (min-width: 769px) {
    transform: rotate(15deg);
    position: absolute;
    top: -89px;
    left: -312px;
    height: 320px;
  }

  @media screen and (min-width: 1024px) {
    transform: rotate(15deg);
    position: absolute;
    top: -125px;
    left: -360px;
    height: 320px;
  }
}

.story-container.in-view .story-inner-container {
  transform: none;
  visibility: visible;
  opacity: 1;
}

.story-container.in-view::before {
  opacity: 1;
}
