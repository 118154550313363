.include-base-css .search_bar {
  margin-bottom: 0px !important;
}

.include-base-css .main_search_bar label, 
.include-base-css .search_within_distance label {
  @apply !font-gothamBold;
  font-size: 18px !important;
  margin-bottom: 6px !important;
  display: block !important;
}

.include-base-css .location-item {
  border: none !important;
  padding-top: 0 !important;
  padding-left: 0 !important;
}

.include-base-css a {
  text-decoration: none;
}

.include-base-css input[type=text], 
.include-base-css select {
  color: #444444 !important;
  background-color: #FFFFFF !important;
  border: 1px solid #979797 !important;
  @apply !font-primary;
  font-size: 16px !important;
  font-weight: normal !important;
  height: 50px !important;
  line-height: 22px !important;
  padding: 14px 40px 14px 18px !important;
  transition: border-color 0.3s ease !important;
  width: 100% !important;
}

.include-base-css button:focus, .include-base-css input[type=text]:focus {
  outline: auto !important;
  border-radius: 0;
}

.include-base-css select {
  background-image: url("data:image/svg+xml,%3Csvg width='33' height='50' viewBox='0 0 33 50' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M18 22L24.5 28.5L31 22' stroke='%23444444' stroke-width='2' stroke-linecap='square'/%3E%3Cline x1='0.5' y1='-2.18557e-08' x2='0.500002' y2='50' stroke='%23979797'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-position: center right 15px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  text-transform: capitalize;
}

.include-base-css .use_my_location {
  margin-top: 10px;
  margin-right: 10px;
}

body .search-container {
  @apply font-primary;
}

body .main_search_label,
body .distance_label,
body .search_limit_label {
  display: block;
  font-size: 18px;
  font-weight: 700;
  line-height: 26px;
  margin-bottom: 8px;
}

body .main_search_label label,
body .distance_label label,
body .search_limit_label label {
  color: #444444;
  margin-bottom: 0;
  letter-spacing: normal;
}

body .search_options input,
body .search_options select {
  color: #444444;
  background-color: #FFFFFF;
  border: 1px solid #979797;
  @apply font-primary;
  font-size: 16px;
  font-weight: 400;
  height: 50px;
  line-height: 22px;
  padding: 14px 40px 14px 18px;
  transition: border-color 0.3s ease;
  width: 100%;
}

body .search_options select {
  background-image: url("data:image/svg+xml,%3Csvg width='33' height='50' viewBox='0 0 33 50' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M18 22L24.5 28.5L31 22' stroke='%23444444' stroke-width='2' stroke-linecap='square'/%3E%3Cline x1='0.5' y1='-2.18557e-08' x2='0.500002' y2='50' stroke='%23979797'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-position: center right 15px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  text-transform: capitalize;
}

body #search_submit {
  color: #444444;
  background-color: #FFFFFF;
  @apply font-gothamBold;
  background-image: none;
  border: 2px solid #444444;
  font-size: 16px;
  line-height: 19px !important;
  position: absolute;
  top: 90px;
  margin-top: 16px !important;
  text-transform: lowercase;
  transition: background-color 0.2s ease-in-out 0s, color 0.2s ease-in-out 0s;
}

@media screen and (min-width: 992px) {
  .store-locator-container #search_submit {
    top: 46px !important;
    height: 50px;
  }
}

body #search_submit:hover {
  background-color: #444444;
  color: #FFFFFF;
}

.include-base-css .location-list button {
  color: #444444;
  background-color: #FFFFFF;
  @apply font-gothamBold;
  background-image: none;
  border: 2px solid #444444;
  font-size: 16px;
  line-height: 7px !important;
  text-transform: lowercase;
  transition: background-color 0.2s ease-in-out 0s, color 0.2s ease-in-out 0s;
  height: 50px;
  padding: 20px;
}

.include-base-css .location-list button:hover {
  background-color: #444444;
  color: #FFFFFF;
}

a#btn-clear-search {
  text-decoration: none;
  color: #444444 !important;
  margin-left: 0 !important;
  text-transform: lowercase !important;
}

a#btn-clear-search:hover {
  text-decoration: none;
  color: #444444 !important;
  opacity: 1;
  border-bottom: 2px solid transparent;
}

.include-base-css .location-list-item-marker img {
  display: none;
}

.include-base-css .location-list-item-marker:before {
  content: '';
  background-position: 8px 7px !important;
  background-size: 15px 25px !important;
  border-bottom: none !important;
  background-image: url("data:image/svg+xml,%3Csvg width='14' height='22' viewBox='0 0 14 22' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M7 22C7 22 14 10.866 14 7C14 3.134 10.866 0 7 0C3.134 0 0 3.134 0 7C0 10.866 7 22 7 22ZM7 11C9.20914 11 11 9.20914 11 7C11 4.79086 9.20914 3 7 3C4.79086 3 3 4.79086 3 7C3 9.20914 4.79086 11 7 11Z' fill='%23444444'/%3E%3C/svg%3E%0A") !important;
  font-size: 12px;
  line-height: 18px;
  padding: 8px !important;
  padding-left: 21px !important;
  background-repeat: no-repeat;
}

.location-list-item-address,
.location-list-item-city-line,
.location-list-item-name,
.location-list-item-name a,
.location-list-item-directions,
.location-list-item-phone {
  font-size: 12px;
  line-height: 18px;
}

.include-base-css .directions-link {
  margin-top: 0 !important;
}

@media screen and (min-width: 650px) and (max-width: 991px) {
  .include-base-css .search_bar {
    flex-direction: column !important;
  }

  .store-locator-container #search_submit {
    top: 90px !important;
  }
}
