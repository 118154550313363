.sidebar__nav{
	ul,li{
		@apply list-none m-0;
	}
	> ul{
		@apply m-0 p-0 border border-grey-1;
		> li{
			@apply border-b border-grey-1 last:border-none;
		}
		a{
			@apply no-underline block pl-9 pr-7 py-6 relative z-0 text-grey-1 font-gothamBold;
			&:before{
				content: '';
				@apply absolute block w-4 bg-grey-7 left-0 top-0 h-full;
			}
			&:hover{
				@apply text-grey-3;
			}
		}
	}
	.is-active{
		a{
			&:before{
				@apply bg-grey-1;
			}
		}
	}
}
.sidebar__nav-m{
	&:after {
	  touch-action: none;
	  content: url('data:image/svg+xml; utf8, <svg width="15" height="9" viewBox="0 0 15 9" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 1L7.5 7.5L14 1" stroke="%23555759" stroke-width="2" stroke-linecap="round"/></svg>');
	  @apply absolute top-0 right-0 w-[50px] h-full pointer-events-none flex items-center justify-center border-l border-grey-5 transition-all;
	}
	&:hover{
		&:after {
			@apply border-black;
		}
	}
	select{
		@apply border-grey-5 text-black;
	}
}

.gctContainer{
	ul,ol{
		@apply my-6 md:my-11;
		ul,ol{
			@apply my-4;
		}
	}
	a{
		&:hover{
			@apply text-grey-3;
		}
	}
	> p > img,
  > img{
  	@apply my-8 md:my-15;
  }
  h1, h2, h3, h4, h5, h6{
  	@apply mt-6 md:mt-11 first:mt-0;
  }
  h4,h5,h6{
    @apply mb-4 md:mb-6;
  }
  p{
  	@apply last:mb-0;
  }
  table tr:nth-child(2) {
  	@apply bg-black;
  	td{
  		@apply py-4;
  	}
  	td, *{
  		@apply text-base text-white font-gothamBold;
  	}
  }
}
