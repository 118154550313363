.three-up {
  .slick-slider {
    @apply px-1 lg:px-0 overflow-hidden;
    .slick-list {
      @apply overflow-visible;
    }
  }
}

.three-up__arrow {
  @apply w-[45px] h-[45px] flex items-center justify-center border;
  &&--mobile {
    @apply w-10 h-10;
  }
}