.pulse {
  animation: pulse 1.5s infinite linear;
}

@keyframes pulse {
  0% {
    transform: translate(-50%, -50%) scale(0);
    opacity: 100%;
  }
  60% {
    transform: translate(-50%, -50%) scale(1);
    opacity: 100%;
  }
  100% {
    transform: translate(-50%, -50%) scale(1.3);
    opacity: 0;
  }
}