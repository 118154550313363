.product-card {
  .yotpo-bottomline {
    @apply !gap-1 items-center;
  }

  .yotpo .yotpo-bottomline .yotpo-icon,
   {
    @apply !text-black;
  }

  .yotpo .yotpo-bottomline .text-m {
    @apply !underline !hidden md:!block;
  }
}


