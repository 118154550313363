.pdp-badges {
	@apply absolute top-auto right-auto left-3 bottom-6 lg:bottom-5 w-[55px] lg:w-[73px] h-[55px] lg:h-[73px] rounded-full flex justify-center;
	font-size: 13px;
	line-height: 15px;
}

.pdp-badge{
	@apply w-full p-2 rounded-full flex items-center justify-center text-center;
}

.product-upsell__image-container .pdp-badges {
	@apply !top-1 !right-2 !bottom-auto !left-auto;
}

.builder-grid .pdp-custom-badges {
	@apply top-[-40px] lg:top-0; 
}

.product-gallery .no-bwp-badge.pdp-badges {
	@apply left-3 lg:left-5 h-[60px] w-[60px] lg:h-[73px] lg:w-[73px] bottom-5 lg:bottom-5;
}

.product-gallery .has-bwp-badge.pdp-badges {
	@apply h-[60px] w-[60px] lg:h-[73px] lg:w-[73px] top-auto right-auto bottom-[5.5rem] md:bottom-[5.75rem] lg:bottom-[6.5rem] left-3 lg:left-5;
}


.product-gallery #bwpContainer {
	@apply absolute h-[60px] w-[60px] lg:h-[73px] lg:w-[73px] right-auto bottom-5 lg:bottom-5;
}