.hotspots {
  .slick-slide > div {
    @apply flex;
  }
  .slick-dots {
    @apply list-none !flex items-center justify-center p-0 mt-8 mb-0;
    li {
      @apply w-3 h-3 border-2 border-grey-1 bg-grey-1 rounded-full text-transparent overflow-hidden mx-1.5 my-0;
      &.slick-active {
        @apply bg-white;
      }
    }
  }
}

.hotspots-title {
  @media screen and (max-width: 380px) {
    font-size: 20px;
  }
}

.hotspots__arrow {
  @apply w-[45px] h-[45px] flex items-center justify-center border hover:bg-grey-1 hover:text-white;
}
.hotspot-modal::before {
  @apply content-[''] absolute inset-0 w-full h-full bg-grey-1 opacity-50;
}
.hotspot-modal-body {
  > * {
    @apply mb-3 lg:mb-4 last:mb-0;
  }
}

.hotspot-cta {
  @apply flex items-center absolute transform;
  button {
    @apply button button--primary lg:text-[14px] lg:leading-[1.2] w-40 h-auto lg:p-[9px];
  }
  span {
    @apply block bg-button-primary-bg;
  }
  button:hover + span {
    @apply bg-grey-1;
  }
}

.dark-label .hotspot-cta {
  @apply flex items-center absolute transform;
  button {
    @apply button button--primary lg:text-[14px] lg:leading-[1.2] w-40 h-auto lg:p-[9px];
  }
  span {
    @apply block bg-button-primary-bg;
  }
  button:hover + span {
    @apply bg-grey-1;
  }
}

.dark-label .hotspot-button {
  @apply bg-grey-1;
}

.dark-label .hotspot-pulse {
  @apply border-grey-1; 
}

.light-label .hotspot-cta {
  @apply flex items-center absolute transform;
  button {
    @apply button min-w-[200px] text-center border-white bg-white text-grey-1 hover:bg-grey-1 hover:border-grey-1 hover:text-white lg:text-[14px] lg:leading-[1.2] w-40 h-auto lg:p-[9px];
  }
  span {
    @apply block bg-white;
  }
  button:hover + span {
    @apply bg-white;
  }
}

.light-label .hotspot-button {
  @apply bg-white;
}

.light-label .hotspot-pulse {
  @apply border-white; 
}

.pink-label .hotspot-cta {
  @apply flex items-center absolute transform;
  button {
    @apply button button--checkout lg:text-[14px] lg:leading-[1.2] w-40 h-auto lg:p-[9px];
  }
  span {
    @apply block bg-primary-2;
  }
  button:hover + span {
    @apply bg-primary-2;
  }
}

.pink-label .hotspot-button {
  @apply bg-primary-2;
}

.pink-label .hotspot-pulse {
  @apply border-primary-2; 
}