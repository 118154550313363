.product-recommendations-slider {
  @apply mb-8 lg:mb-0 md-:-mx-12 lg:mx-0 lg:-mr-5 relative;

  &:hover {
    .slick-next,
    .slick-prev {
      @apply !block opacity-100;
    }
  }

  .slick-track {
    @apply flex px-4 md-:px-12 lg:pl-0 lg:pr-10;
  }

  .slick-slide {
    @apply mr-5 last:mr-0 h-[inherit];

    & > div {
      @apply h-full;
    }

  }

	div[class="slick-list"]{
		@apply lg:pl-[60px];
	}
}

.product-recommendations-slider .slick-next,
.product-recommendations-slider .slick-prev {
  @apply opacity-0;
  font-size: 0;
  animation: fade-in-top 0.3s ease-in-out both;

  &:before {
    content: '';
    background-image: url('/images/arrow-indicator-next.svg');
    @apply w-[42px] h-[42px] lg:!block;
  }

  @media screen and (min-width: 1024px) {
    top: 27%;
  }

  @media screen and (min-width: 1100px) {
    top: 23%;
  }

  @media screen and (min-width: 1330px) {
    top: 27%;
  }
}

.product-recommendations-slider .slick-prev {
  @apply absolute left-[35px] w-[42px] h-[42px] !hidden z-[1];

  &:before {
    transform: rotate(180deg);
  }
}

.product-recommendations-slider .slick-next {
  @apply absolute right-[35px] w-[42px] h-[42px] !hidden z-[1];
}

/**
 * ----------------------------------------
 * animation fade-in-top
 * ----------------------------------------
 */
@keyframes fade-in-top {
  0% {
    transform: translateY(-5px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
