.markdownContainer {
  max-width: 85rem;
  margin: 0 auto;
  padding-left: 1.5rem;
  padding-right: 1.5rem; 
  width: 100%;
  > p > img,
  > img{
    margin: 32px 0;
    @screen md {
      margin: 60px 0;
    }
  }
}
