.or-separator {
  display: none;
}

.bwp-widget-loaded ~ .or-separator {
  display: flex;
  align-items: center;
  text-align: center;
  background-color: white;
  margin-top: 0;
  width: 100%;
}

.bwp-widget-loaded {
  order: 2;
}

.bwp-widget-loaded ~ .or-separator::before,
.bwp-widget-loaded ~ .or-separator::after {
  content: "";
  border-bottom: 1px solid #444444;
  flex: 1;
}

.bwp-widget-loaded ~ .or-separator:not(:empty)::before {
  margin-right: .5em;
}

.bwp-widget-loaded ~ .or-separator:not(:empty)::after {
  margin-left: .5em;
}

#roaRatingIframe {
  width: auto !important;
  text-align: center;
  margin: 0 auto;
  display: flex;
  padding-left: 26px;
}

#roaReviewsIframe {
  margin-top: 24px;
  max-width: 850px !important;
  margin: 0 auto;
  text-align: center;
  margin-bottom: 24px;
}
