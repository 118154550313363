body.show-custom-cursor {
  cursor: url(https://cdn.shopify.com/s/files/1/0905/3204/files/brio-cursor.svg?v=1718043314) 5 5, auto;
}

body.show-custom-cursor * {
  cursor: inherit;
}

body.show-custom-cursor a,
body.show-custom-cursor button {
  cursor: url(https://cdn.shopify.com/s/files/1/0905/3204/files/brio-cursor-hover.svg?v=1718043323) 5 5, pointer;
}